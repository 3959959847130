$(document).ready(function(){







    !function(){
        function init(){
            $(this).find('.m-slider__body').slick({
                arrows:false,
                dots:true,
                dotsClass:'m-dots',
                appendDots:   $(this).find('.m-slider__nav')
            });
         
        }
        $('.m-slider').each(init);
    }()
  


    let prevArrow="<div class='arrow arrow--prev'></div>";
    let nextArrow="<div class='arrow arrow--next'></div>";
    /**/ 
    !function(){
        function init(){
            $(this).find('.slider-good__body').slick({
                slidesToShow:4,
                slidesToScroll:4,
                arrows:true,
                dots:false,
                prevArrow:prevArrow,
                nextArrow:nextArrow,
                dotsClass:'m-dots',
                appendDots:   $(this).find('.slider-good__nav'),
                responsive:[
                    {
                        breakpoint:960,
                        settings:{
                            slidesToShow:3,
                            slidesToScroll:3
                        }
                    },
                    {
                        breakpoint:720,
                        settings:{
                            slidesToShow:2,
                            slidesToScroll:2
                        }
                    },
                    
                    {
                        breakpoint:520,
                        settings:{
                            slidesToShow:1,
                            slidesToScroll:1
                        }
                    }
                ]
            });
         
        }
        $('.slider-good').each(init)
    }()



    /**/ 
    !function(){
        function init(){
            var self=$(this);
            var links=self.find('.js-tabs__link');
            var content=self.find('.js-tabs__content');

            links.on('click',function(e){
               
                var id=links.index(this);
                $(links).removeClass('js-tabs__link--active')
                $(this).addClass('js-tabs__link--active')
                content.css('display','none');
                content.eq(id).slideDown();
                //console.log(id);
                e.preventDefault();
            });
            links.eq(0).trigger('click');

        }
        $('.js-tabs').each(init);
    }();
  

    var Menu=function(){
        function open(){
           
            MenuCatalog.close();
            $('.h-menu').addClass('h-menu--active');
            $('body').css('overflow','hidden');
        }
        function close(){
            $('.h-menu').removeClass('h-menu--active');
            $('body').css('overflow','auto');
        }
        $('.header-open-menu').on('click',open);
        $('.h-menu__close').on('click',close);

        return {
            open:open,
            close:close,
        }
    }();
    //console.log(Menu);

    var MenuCatalog=function(){
        function open(){
            Menu.close();
            $('body').css({'overflow':'hidden'});
            $('.categories').addClass('is-open');
            
        }
        function close(){
            $('.categories').removeClass('is-open');
            $('body').css('overflow','auto');
        }
        $('.categories__toggle').on('click',function(){
            if( $('.categories').hasClass('is-open')){
                
                close();
            }else{

                open();
            }
        });
        $('.categories').on('click',function(e){
            if(e.target==$('.categories')[0]){
                close();
            }
        })
        return {
            open:open,
            close:close,
        }
    }();
   



    !function(){
        $('.b-card__slider-big').slick({
            slidesToShow:1,
            slidesToScroll:1,
            arrows:false,
            dots:false,
            infinite:false,
            asNavFor:$('.b-card__slider-nav'),
        });
        $('.b-card__slider-nav').slick({
            slidesToShow:3,
            slidesToScroll:3,
            arrows:false,
            dots:false,
            asNavFor:$('.b-card__slider-big'),
            responsive:[
                {
                    breakpoint:400,
                    settings:{
                        slidesToShow:2,
                        slidesToScroll:2,

                    }
                }
            ]
        })
    }();



    !function(){
        function init(){  
            var self=$(this);
            var links= self.find('.card-tabs__link');
            var content= self.find('.card-tabs__content');
           // console.log(links);
            links.on('click',function(){
                var id=links.index(this);
                links.removeClass('card-tabs__link--active');
                links.eq(id).addClass('card-tabs__link--active');
                content.slideUp();
                content.eq(id).slideDown();
            });
            links.eq(0).trigger('click');
        }
        $('.card-tabs').each(init);
    }();




    !function(){
        function SetVal(v){
            var parent=$(this).parents('.counter');
            var val=parent.find('.counter__input');
            if(isNaN(parseInt(val.val()))){
                val.val(0)
            }
            if(v==1){
                val.val(parseInt(val.val())+1);
            }else{
                var q=parseInt(val.val())-1;
                q=q<0?0:q;
                val.val(q);
            }
           
        }
        $('.counter__btn.counter__btn--dec').on('click',function(){
            SetVal.call(this,0)
        });
        $('.counter__btn.counter__btn--inc').on('click',function(){
            SetVal.call(this,1)
        });
    }();

    
  
    
    !function(){
        function closeEl(){
            if(!$(this).hasClass('is-active')){
                $(this).removeClass('is-open');
                $(this).find('.el-category').slideUp();
            }else{
                $(this).addClass('is-open');
            }
        }
        function init(){
            var self=$(this);
            var link=self.find('.categories__link');
            var list=self.find('.el-category');
            closeEl.call(this);
            link.on('click',function(){
             
                self.toggleClass('is-open');
                list.slideToggle();
                
            })
        }
        $('.categories .categories__item').each((e)=>{
            
        })
        $('.categories .categories__item').each(init)
    }();




    try{
        $('.popup-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            }
        });
    }
    catch(e){
       console.log(e);
    }






});